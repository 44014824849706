import React, { useState } from "react";
import Layout from "../../Layout";
import SEO from "../../seo";
import "./style.scss";

export default function FeeStructurePage() {
  return (
    <Layout>
      <SEO
        title="Fee Structure | Chinmaya Vidyalaya Vasant Vihar"
        description="The current year’s school fee structure for all classes, with a monthly, quarterly and annual view."
      />
      <div className="feestructure">
        <h1 className="heading">Fee Structure</h1>

        <h3>(As per Fee Structure 2022-23)</h3>

        <div className="feestructure__table">
          <div className="feestructure__table__row feestructure__table__row--heading">
            <div className="feestructure__table__col1">Class</div>
            <div className="feestructure__table__col2">Monthly</div>
            <div className="feestructure__table__col3">Quarterly</div>
            <div className="feestructure__table__col4">Annual</div>
          </div>

          <div className="feestructure__table__row">
            <div className="feestructure__table__col1">Nur-Prep </div>
            <div className="feestructure__table__col2">6155</div>
            <div className="feestructure__table__col3">18465</div>
            <div className="feestructure__table__col4">73860</div>
          </div>

          <div className="feestructure__table__row">
            <div className="feestructure__table__col1">I to III</div>
            <div className="feestructure__table__col2">6411</div>
            <div className="feestructure__table__col3">19233</div>
            <div className="feestructure__table__col4">76932</div>
          </div>

          <div className="feestructure__table__row">
            <div className="feestructure__table__col1">IV</div>
            <div className="feestructure__table__col2">7101</div>
            <div className="feestructure__table__col3">21303</div>
            <div className="feestructure__table__col4">85212</div>
          </div>

          <div className="feestructure__table__row">
            <div className="feestructure__table__col1">V-VI </div>
            <div className="feestructure__table__col2">7170</div>
            <div className="feestructure__table__col3">21510</div>
            <div className="feestructure__table__col4">86040</div>
          </div>

          <div className="feestructure__table__row">
            <div className="feestructure__table__col1">VII-X</div>
            <div className="feestructure__table__col2">7942</div>
            <div className="feestructure__table__col3">23826</div>
            <div className="feestructure__table__col4">95304</div>
          </div>

          <div className="feestructure__table__row">
            <div className="feestructure__table__col1">XI-XII-Non SC </div>
            <div className="feestructure__table__col2">8127</div>
            <div className="feestructure__table__col3">24381</div>
            <div className="feestructure__table__col4">97524</div>
          </div>

          <div className="feestructure__table__row">
            <div className="feestructure__table__col1">XI-XII-SC </div>
            <div className="feestructure__table__col2">8975</div>
            <div className="feestructure__table__col3">26925</div>
            <div className="feestructure__table__col4">107700</div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
